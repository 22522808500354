export const getOverviewData = () => {
    return [
        {
            sectionImage: 'Overview/credit-one-bank-club-logo.png',
            paragraph: 'Intimate and sleek, this club features incredible sideline views, turnkey technology, and stunning finishes creating an impressive atmosphere.',
            area: '9,400',
            reception: '300',
            banquet: '150'
        }
    ]
}

export const getCarouselImages = () => {
    return [
		'CreditOneClub/Photos/credit-one-club-new1.jpg',
        'CreditOneClub/Photos/credit-one-club-updated.jpg',
        'CreditOneClub/Photos/credit-one-club-01.jpg',
		// 'CreditOneClub/Photos/credit-one-club-07.jpg',
        'CreditOneClub/Photos/credit-one-club-02.jpg',
        // 'CreditOneClub/Photos/credit-one-club-03.jpg',
        'CreditOneClub/Photos/credit-one-club-04.jpg',
        'CreditOneClub/Photos/credit-one-club-05.jpg',
        'CreditOneClub/Photos/credit-one-club-06.jpg',
        'CreditOneClub/Photos/credit-one-club-08.jpg',
        'CreditOneClub/Photos/credit-one-club-09.jpg',
        'CreditOneClub/Photos/credit-one-club-10.jpg',
        'CreditOneClub/Photos/credit-one-club-11.jpg',
        'CreditOneClub/Photos/credit-one-club-12.jpg',
        'CreditOneClub/Photos/credit-one-club-13.jpg',
        'CreditOneClub/Photos/credit-one-club-14.jpg'
    ]
}

export const getCarouselInspirationImages = () => {
    return [
		'CreditOneClub/Inspiration/credit-one-club-event-1.jpg',
		'CreditOneClub/Inspiration/credit-one-club-event-2.jpg',
		'CreditOneClub/Inspiration/credit-one-club-event-3.jpg',
        'CreditOneClub/Inspiration/credit-one-inspiration-1.jpg',
        'CreditOneClub/Inspiration/credit-one-inspiration-2.jpg',
        'CreditOneClub/Inspiration/credit-one-inspiration-3.jpg'
    ]
}

export const getFieldViewImages = () => {
    return [
        'CreditOneClub/FieldView/VR_Section_C112_8_11_Black.jpg',
        'CreditOneClub/FieldView/VR_Section_C112_8_11_Down.jpg',
        'CreditOneClub/FieldView/VR_Section_C112_8_11_Front.jpg',
        'CreditOneClub/FieldView/VR_Section_C112_8_11_Left.jpg',
        'CreditOneClub/FieldView/VR_Section_C112_8_11_Right.jpg',
        'CreditOneClub/FieldView/VR_Section_C112_8_11_Up.jpg'
    ]
}

export const getLayoutImages = () => {
    return [
        'CreditOneClub/Layout/layout-credit-one-club.jpg',
		'CreditOneClub/Layout/layout-credit-one-club2.jpg',
		'CreditOneClub/Layout/layout-credit-one-club3.jpg',
		'CreditOneClub/Layout/layout-credit-one-club4.jpg',
		'CreditOneClub/Layout/layout-credit-one-club5.jpg',
    ]
}

export const getLayoutImagesMobile = () => {
    return [
        'CreditOneClub/Layout/layout-credit-on-club-mobile.png'
    ]
}
